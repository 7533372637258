import type { App } from "vue";
import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";
import { CONFIG_OPTIONS as config } from "@src/globals";

/** Adds target="_blank" to all a-tags that lead to an external site. */
function addTargetBlankToExternalLinks() {
    const links = document.querySelectorAll("a");
    links.forEach((link) => {
        try {
            const url = new URL(link.href);
            if (url.hostname !== window.location.hostname) {
                link.setAttribute("target", "_blank");
                link.setAttribute("rel", "noopener noreferrer");
            }
        } catch (e) {}
    });
}

function addPlaceholderToImages() {
    const placeholderPath = `/images/${config.global.placeholderFilename}`;
    const images = document.querySelectorAll("img");
    images.forEach((image) => {
        try {
            if (image.src == "") {
                image.src = placeholderPath;
            }

            if (!image.complete) {
                image.style.background = `url('${placeholderPath}')`;
                image.style.backgroundSize = "cover";
                image.style.backgroundPosition = "center";
            }

            image.onload = (event) => {
                image.style.background = "none";
                image.style.backgroundSize = "auto";
                image.style.backgroundPosition = "auto";
            };

            image.onerror = () => (image.src = placeholderPath);
        } catch (e) {}
    });
}

const externalLinksMixin = {
    mounted() {
        addTargetBlankToExternalLinks();
    },
    updated() {
        addTargetBlankToExternalLinks();
    },
};

const imgErrorEvent = {
    mounted() {
        addPlaceholderToImages();
    },
    updated() {
        addPlaceholderToImages();
    },
};

export default (app: App) => {
    if (import.meta.env.SSR) return;

    let url =
        import.meta.env.PUBLIC_GRAPHQL_URL ?? process.env.PUBLIC_GRAPHQL_URL;

    if (!url) {
        console.error("PUBLIC_GRAPHQL_URL is not defined in .env file");
        return;
    }

    const apolloProvider = createApolloProvider({
        defaultClient: new ApolloClient({
            uri: url,
            cache: new InMemoryCache(),
        }),
    });

    app.mixin(externalLinksMixin);
    app.mixin(imgErrorEvent);
    app.use(apolloProvider);
};
